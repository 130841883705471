
import { defineComponent } from "vue";
import BaseHeader2 from "@/components/BaseHeader2.vue";
import InputBox from "@/components/member/InputBox.vue";
import DefaultButton from "@/components/common/DefaultButton.vue";
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
  name: "SetPassword",
  components: { BaseHeader2, DefaultButton, InputBox },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      email: "",
    };
  },
  validations: {
    email: { required, email },
  },
  methods: {
    sendVerificationEmail() {
      this.$flutter.callHandler("showToast", {
        message: "메일이 전송되었습니다.",
        color: "success",
      });
      if (!this.v$.$invalid) {
        this.$axios
          .post("/member/password/reset/request", {
            email: this.email,
          })
          .then(() => {
            this.email = "";
          });
      }
    },
  },
});
